import React, { useState } from "react";
import axios from "axios";
import "tailwindcss/tailwind.css";

const API_URL =
  "https://cartoon-yourself.p.rapidapi.com/facebody/api/portrait-animation/portrait-animation";
const API_KEY = "4da4fb2038msheabecaa2972e1c2p1c128cjsn6e7cd7a91a93";
const API_HOST = "cartoon-yourself.p.rapidapi.com";

const sharedClasses = {
  container:
    "flex flex-col items-center justify-center min-h-screen bg-olive-drab-700 text-sand-300 p-4",
  textCenter: "text-center",
  textHeader: "text-4xl font-bold mb-2 text-sand-100 uppercase tracking-wider",
  textSubHeader: "text-lg text-sand-400 mb-6",
  inputContainer: "flex flex-col items-center mb-6 w-full max-w-md",
  inputFile:
    "mb-4 p-2 bg-olive-drab-800 border-2 border-sand-500 rounded w-full file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-olive-drab-600 file:text-sand-200 hover:file:bg-olive-drab-500",
  select: "mb-4 p-2 bg-olive-drab-800 border-2 border-sand-500 rounded w-full",
  button:
    "px-6 py-3 rounded bg-army-green hover:bg-army-green-dark text-sand-100 font-bold uppercase tracking-wide shadow-lg transform transition duration-200 ease-in-out hover:scale-105",
  errorText: "text-red-500 mt-4 font-semibold",
  resultContainer: "mt-6 text-center",
  resultImage:
    "max-w-full max-h-screen rounded-lg border-4 border-sand-500 shadow-2xl",
  downloadButton:
    "mt-4 px-4 py-2 rounded bg-sand-500 text-olive-drab-800 font-bold uppercase tracking-wide shadow-lg transform transition duration-200 ease-in-out hover:scale-105",
  footer: "mt-8 text-center text-sand-400",
};

const CartoonSelfie = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [type, setType] = useState("3d_cartoon"); // Default type

  const handleImageUpload = (e) => {
    setSelectedImage(e.target.files[0]);
    setResultImage(null);
    setError("");
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleCreateCartoon = async () => {
    if (!selectedImage) {
      setError("Please select an image first.");
      return;
    }

    setLoading(true);
    setError("");
    const formData = new FormData();
    formData.append("image", selectedImage);
    formData.append("type", type);

    const options = {
      method: "POST",
      url: API_URL,
      headers: {
        "x-rapidapi-key": API_KEY,
        "x-rapidapi-host": API_HOST,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    try {
      const response = await axios.request(options);
      const { image_url } = response.data.data;
      setResultImage(image_url);
    } catch (err) {
      setError("Monthly free credits Expired");
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    if (resultImage) {
      const link = document.createElement("a");
      link.href = resultImage;
      link.download = "cartoon_selfie.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className={`${sharedClasses.container} bg-camouflage`}>
      <div className={sharedClasses.textCenter}>
        <h1 className={`${sharedClasses.textHeader} text-shadow-lg`}>
          Mission: Beautify You!!
        </h1>
        <p className={sharedClasses.textSubHeader}>
          Transform your portrait with AI magic.
        </p>
      </div>
      <div
        className={`${sharedClasses.inputContainer} bg-olive-drab-800 p-6 rounded-lg shadow-xl`}
      >
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          className={sharedClasses.inputFile}
        />
        <select
          value={type}
          onChange={handleTypeChange}
          className={sharedClasses.select}
        >
          <option value="3d_cartoon">3D Cartoon</option>
          <option value="pixar">Pixar</option>
          <option value="pixar_plus">Pixar Pro</option>
          <option value="angel">Angel</option>
          <option value="angel_plus">Angel Pro</option>
          <option value="demon">Demon</option>
          <option value="ukiyoe_cartoon">Ukiyo-e</option>
          <option value="amcartoon">American Manga</option>
          <option value="famous">World Famous Paintings</option>
          <option value="3d">3D Effects</option>
          <option value="3d_game">3D Game Effects</option>
          <option value="bopu_cartoon">Popper</option>
          <option value="western">Western</option>
          <option value="avatar">Avatar</option>
          <option value="jpcartoon_head">Japanese Manga (portrait)</option>
          <option value="head">Pencil Drawing (head)</option>
        </select>
        <button
          onClick={handleCreateCartoon}
          className={sharedClasses.button}
          disabled={loading || !selectedImage}
        >
          {loading ? "Processing..." : "Create Cartoon"}
        </button>
      </div>
      {error && (
        <div className={`${sharedClasses.errorText} bg-red-800 p-3 rounded`}>
          {error}
        </div>
      )}
      {resultImage && (
        <div className={sharedClasses.resultContainer}>
          <h2 className={`${sharedClasses.textHeader} text-2xl`}>Result:</h2>
          <img
            src={resultImage}
            alt="Result"
            className={sharedClasses.resultImage}
          />
          <button
            onClick={handleDownload}
            className={sharedClasses.downloadButton}
          >
            Download Image
          </button>
        </div>
      )}
      <footer className={sharedClasses.footer}>
        ©Trusty Tools | info@mdzaid.us.kg
      </footer>
    </div>
  );
};

export default CartoonSelfie;
