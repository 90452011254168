import React, { useState, useEffect } from "react";
import axios from "axios";
import { Download } from "lucide-react";

export default function App() {
  const [prompt, setPrompt] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [textLoader, setTextLoader] = useState("");

  useEffect(() => {
    let interval;
    if (loading) {
      const messages = [
        "Started creating masterpiece",
        "You know you can use this unlimitedly without need to login and all",
        "Almost complete",
      ];
      let index = 0;
      setTextLoader(messages[index]);
      interval = setInterval(() => {
        index = (index + 1) % messages.length;
        setTextLoader(messages[index]);
      }, 5000); // Change message every 2 seconds
    }
    return () => clearInterval(interval); // Clear interval when loading stops
  }, [loading]);

  const generateImage = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://generative.mdzaiduiux.workers.dev?prompt=${encodeURIComponent(
          prompt
        )}`,
        {
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([response.data], { type: "image/png" });
      const imageUrl = URL.createObjectURL(blob);
      setImage(imageUrl);
    } catch (error) {
      console.error("Error generating image:", error);
    } finally {
      setLoading(false);
    }
  };

  const downloadImage = () => {
    const a = document.createElement("a");
    a.href = image;
    a.download = "generated-image.png";
    a.click();
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-black text-white p-4">
      <img
        src="https://i.postimg.cc/bwTVC2dN/Think.png"
        alt="AI Image Generator"
        className="w-64 h-auto mb-6 mt-4 rounded-2xl"
      />
      <div className="w-full max-w-md mb-2">
        <div className="input-container">
          <input
            type="text"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt..."
          />
        </div>
      </div>

      <button
        onClick={generateImage}
        disabled={loading}
        className="button w-26 max-w-md mb-4"
      >
        {loading ? "Wait Patiently" : "Generate"}
      </button>

      {loading && (
        <div className="loader-container italic text-light-gray text-center mt-4">
          {textLoader}
        </div>
      )}

      {image && (
        <div className="w-full max-w-md">
          <img
            src={image}
            alt="Generated by AI"
            className="w-full rounded-lg shadow-lg mb-4"
          />
          <button onClick={downloadImage} className="button w-26 max-w-md mb-4">
            <Download className="mr-2" size={15} />
            Download
          </button>
        </div>
      )}

      <style jsx>{`
        .input-container {
          display: flex;
          background: white;
          border-radius: 1rem;
          background: linear-gradient(173deg, #23272f 0%, #14161a 100%);
          box-shadow: 10px 10px 20px #0e1013, -10px -10px 40px #383e4b;
          padding: 0.3rem;
          gap: 0.3rem;
          width: 100%;
        }
        .loader-container {
          font-style: italic;
          color: #e0e0e0; /* Lighter gray color */
          animation: blink 2s infinite; /* Add blink animation */
        }

        @keyframes blink {
          0%,
          100% {
            opacity: 1;
          } /* Full opacity at the start and end */
          50% {
            opacity: 0;
          } /* Fully transparent in the middle */
        }

        .input-container input {
          border-radius: 0.8rem;
          background: #23272f;
          box-shadow: inset 5px 5px 10px #0e1013, inset -5px -5px 10px #383e4b,
            0px 0px 100px rgba(255, 212, 59, 0),
            0px 0px 100px rgba(255, 102, 0, 0);
          width: 100%;
          padding: 1rem;
          border: none;
          border: 1px solid transparent;
          color: white;
          transition: all 0.2s ease-in-out;
        }

        .input-container input:focus {
          border: 1px solid #ffd43b;
          outline: none;
          box-shadow: inset 0px 0px 10px rgba(255, 102, 0, 0.5),
            inset 0px 0px 10px rgba(255, 212, 59, 0.5),
            0px 0px 100px rgba(255, 212, 59, 0.5),
            0px 0px 100px rgba(255, 102, 0, 0.5);
        }
        .button {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          background-color: #000;
          color: #fff;
          font-size: 13px;
          border: 0.5px solid rgba(0, 0, 0, 0.1);
          padding: 12px;
          height: 48px;
          border-radius: 15px 15px 12px 12px;
          cursor: pointer;
          position: relative;
          will-change: transform;
          transition: all 0.1s ease-in-out 0s;
          user-select: none;
          background-image: linear-gradient(
              to right,
              rgba(0, 0, 0, 0.8),
              rgba(0, 0, 0, 0)
            ),
            linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
          background-position: bottom right, bottom right;
          background-size: 100% 100%, 100% 100%;
          background-repeat: no-repeat;
          box-shadow: inset -4px -10px 0px rgba(255, 255, 255, 0.4),
            inset -4px -8px 0px rgba(0, 0, 0, 0.3),
            0px 2px 1px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(255, 255, 255, 0.1);
          transform: perspective(70px) rotateX(5deg) rotateY(0deg);
        }

        .button::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.2),
            rgba(0, 0, 0, 0.5)
          );
          z-index: -1;
          border-radius: 15px;
          box-shadow: inset 4px 0px 0px rgba(255, 255, 255, 0.1),
            inset 4px -8px 0px rgba(0, 0, 0, 0.3);
        }

        .button::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-image: linear-gradient(
              to right,
              rgba(0, 0, 0, 0.8),
              rgba(0, 0, 0, 0)
            ),
            linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
          background-position: bottom right, bottom right;
          background-size: 100% 100%, 100% 100%;
          background-repeat: no-repeat;
          z-index: -1;
          border-radius: 15px;
        }

        .button:focus {
          outline: none;
        }

        @media (max-width: 500px) {
          .input-container {
            flex-direction: column;
          }

          .input-container input {
            border-radius: 0.8rem;
          }
        }
      `}</style>
    </div>
  );
}
