import React, { useState } from 'react';

const VehicleRCCheck = () => {
  const [rcNumber, setRcNumber] = useState('');
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch('https://rto-vehicle-information-verification-india.p.rapidapi.com/api/v1/rc/vehicleinfo', {
        method: 'POST',
        headers: {
          'x-rapidapi-key': 'af73c71b38msh8aac462c8b960dcp1412b4jsn5374646cc1b6',
          'x-rapidapi-host': 'rto-vehicle-information-verification-india.p.rapidapi.com',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reg_no: rcNumber,
          consent: 'Y',
          consent_text: 'I hereby declare my consent agreement for fetching my information via AITAN Labs API',
        }),
      });

      if (!response.ok) {
        throw new Error('You have exceeded the monthly quota for requests on your current plan');
      }

      const data = await response.json();
      setVehicleDetails(data.result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      backgroundImage: 'url(https://i.postimg.cc/2yxWWyx7/2d-graphic-colorful-wallpaper-with-grainy-gradients.jpg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
      fontFamily: "'Poppins', sans-serif",
      padding: '20px',
      boxSizing: 'border-box',
    }}>
      <div style={{
        width: '100%',
        maxWidth: '500px',
        background: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(10px)',
        borderRadius: '20px',
        padding: '40px',
        boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
        border: '1px solid rgba(255, 255, 255, 0.18)',
      }}>
        <h2 style={{
          color: '#fff',
          textAlign: 'center',
          marginBottom: '30px',
          fontSize: '28px',
          fontWeight: '600',
        }}>Vehicle RC Check</h2>

        {!vehicleDetails && (
          <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
            <input
              type="text"
              value={rcNumber}
              onChange={(e) => setRcNumber(e.target.value)}
              placeholder="Enter RC Number"
              style={{
                padding: '15px',
                marginBottom: '20px',
                borderRadius: '10px',
                border: 'none',
                background: 'rgba(255, 255, 255, 0.2)',
                color: '#fff',
                fontSize: '16px',
              }}
              required
            />
            <button type="submit" disabled={loading} style={{
              padding: '15px',
              borderRadius: '10px',
              border: 'none',
              background: 'rgba(255, 255, 255, 0.3)',
              color: '#fff',
              fontSize: '16px',
              cursor: 'pointer',
              transition: 'background 0.3s ease',
            }}>
              {loading ? 'Checking...' : 'Check Vehicle'}
            </button>
            {error && <p style={{color: '#ff6b6b', marginTop: '20px', textAlign: 'center'}}>{error}</p>}
          </form>
        )}

        {vehicleDetails && (
          <div>
            <h3 style={{color: '#fff', textAlign: 'center', marginBottom: '20px'}}>Vehicle Details</h3>
            {Object.entries(vehicleDetails).map(([key, value]) => (
              <p key={key} style={{
                color: '#fff',
                marginBottom: '10px',
                background: 'rgba(255, 255, 255, 0.1)',
                padding: '10px',
                borderRadius: '8px',
              }}>
                <strong>{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}:</strong> {value || 'Not Available'}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default VehicleRCCheck;