import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaSearch, FaWater, FaWind } from "react-icons/fa";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('https://i.postimg.cc/Kvrkp1BP/rm218-bb-07.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  z-index: 2;
`;

const Container = styled.div`
  max-width: 400px;
  width: 100%;
  padding: 2rem;
  background: linear-gradient(135deg, #00feba, #5b548a);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  color: #fff;
  position: relative;
`;

const SearchBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const SearchInput = styled.input`
  flex-grow: 1;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  outline: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const SearchButton = styled.button`
  background: transparent;
  border: none;
  margin-left: -40px;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
`;

const WeatherInfo = styled.div`
  text-align: center;
`;

const WeatherIcon = styled.img`
  display: block;
  margin: 0 auto;
`;

const Temperature = styled.h1`
  font-size: 3rem;
  margin: 0;
`;

const Description = styled.p`
  font-size: 1.2rem;
  margin: 0.5rem 0 1.5rem;
  text-transform: capitalize;
`;

const WeatherDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
`;

const DetailIcon = styled.span`
  font-size: 1.5rem;
  margin-right: 0.5rem;
`;

const DetailText = styled.span`
  font-size: 1.1rem;
`;

const NotFound = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const SuggestionBox = styled.div`
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0 0 10px 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
`;

const SuggestionItem = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: #333;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

function WeatherAPI() {
  const [city, setCity] = useState("");
  const [weatherData, setWeatherData] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = () => {
    const APIKey = "9e1ac06a6f72dc48dc80d70b4f040583";

    if (city === "") return;

    fetch(
      `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=metric&appid=${APIKey}`
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.cod === "404") {
          setNotFound(true);
          setWeatherData(null);
          return;
        }

        setNotFound(false);
        setWeatherData(json);
        setSuggestions([]);
      });
  };

  const fetchSuggestions = (query) => {
    const APIKey = "9e1ac06a6f72dc48dc80d70b4f040583";

    if (query === "") {
      setSuggestions([]);
      return;
    }

    fetch(
      `https://api.openweathermap.org/data/2.5/find?q=${query}&type=like&appid=${APIKey}`
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.cod === "200") {
          const uniqueSuggestions = Array.from(new Set(json.list.map((item) => item.name)))
            .map((name) => {
              return json.list.find((item) => item.name === name);
            });
          setSuggestions(uniqueSuggestions);
        }
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    fetchSuggestions(city);
  }, [city]);

  return (
    <PageWrapper>
      <ContentWrapper>
        <Container>
          <SearchBox>
            <SearchInput
              type="text"
              placeholder="Enter your location"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <SearchButton onClick={handleSearch}>
              <FaSearch />
            </SearchButton>
            {suggestions.length > 0 && (
              <SuggestionBox>
                {suggestions.map((suggestion, index) => (
                  <SuggestionItem
                    key={index}
                    onClick={() => {
                      setCity(suggestion.name);
                      setSuggestions([]);
                      handleSearch();
                    }}
                  >
                    {suggestion.name}, {suggestion.sys.country}
                  </SuggestionItem>
                ))}
              </SuggestionBox>
            )}
          </SearchBox>

          {weatherData && (
            <WeatherInfo>
              <WeatherIcon
                src={`https://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png`}
                alt="weather icon"
              />
              <Temperature>
                {parseInt(weatherData.main.temp)}°C
              </Temperature>
              <Description>{weatherData.weather[0].description}</Description>
              <WeatherDetails>
                <DetailItem>
                  <DetailIcon><FaWater /></DetailIcon>
                  <DetailText>{weatherData.main.humidity}% Humidity</DetailText>
                </DetailItem>
                <DetailItem>
                  <DetailIcon><FaWind /></DetailIcon>
                  <DetailText>{parseInt(weatherData.wind.speed)} km/h Wind</DetailText>
                </DetailItem>
              </WeatherDetails>
            </WeatherInfo>
          )}

          {notFound && (
            <NotFound>
              <p>Oops! Location not found!</p>
            </NotFound>
          )}
        </Container>
      </ContentWrapper>

    </PageWrapper>
  );
}

export default WeatherAPI;