import React, { useState, useEffect } from "react";
import { ArrowRight, PlayCircle, StopCircle, PlusCircle } from "lucide-react";
import emailjs from "emailjs-com";

const quotes = [
  "Kal ho na ho.",
  "Aaj khush toh bahut hoge tum.",
  "Don't underestimate the power of a common man.",
  "Mere paas maa hai.",
  "Zindagi badi honi chahiye, lambi nahi.",
  "Kehte hain agar kisi cheez ko dil se chaho toh poori kainaat usse tumse milane ki koshish mein lag jaati hai.",
  "Bade bade deshon mein aisi chhoti chhoti baatein hoti rehti hain.",
  "Zindagi jeene ke do hi tarike hote hain... ek jo ho raha hai hone do, bardasht karte jao, ya phir zimmedari uthao usse badalne ki.",
  "Tumhara naam kya hai Basanti?",
  "All is well.",
  "Picture abhi baaki hai mere dost.",
  "Ek baar jo maine commitment kar di, uske baad toh main khud ki bhi nahi sunta.",
  "Kitne aadmi the?",
  "Mogambo khush hua.",
];

const subquotes = [
  "I can talk English, I can walk English, I can laugh English because English is a very phunny language.",
  "Jo darr gaya, samjho marr gaya.",
  "Hum jahan khade hote hain, line wahi se shuru hoti hai.",
  "Rishtey mein toh hum tumhare baap lagte hain, naam hai Shahenshah.",
  "Dosti ka ek usool hai madam - no sorry, no thank you.",
  "Main apni favorite hoon.",
  "Koi bhi desh perfect nahi hota, usse perfect banana padta hai.",
  "Keh diya na, bas keh diya.",
  "Yeh dosti hum nahi todenge.",
  "Itni shiddat se maine tumhe paane ki koshish ki hai, ki har zarre ne mujhe tumse milane ki saazish ki hai.",
  "Don't angry me.",
  "Tumse na ho payega.",
  "Insaan ko dibbe mein sirf tab hona chahiye jab woh mar chuka ho.",
  "Aata majhi satakli.",
  "Mujhse dosti karoge?",
  "Dil se hai, pyar se hai, khushi se hai, main kahin bhi hoon, mere paas hai.",
  "Joh main bolta hoon woh main karta hoon, joh main nahi bolta woh main definitely karta hoon.",
  "Tumhara naam kya hai Basanti?",
  "Pushpa, I hate tears.",
  "Zindagi mein kuch banna ho",
];

function App() {
  const [userEmail, setUserEmail] = useState("");
  const [goalTitle, setGoalTitle] = useState("");
  const [goalTime, setGoalTime] = useState({ hours: 0, minutes: 0 });
  const [timeSpent, setTimeSpent] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [error, setError] = useState("");
  const [taskCompleted, setTaskCompleted] = useState(false);

  // Load data from localStorage on initial render
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("timeTrackerData"));
    if (savedData) {
      setUserEmail(savedData.userEmail);
      setGoalTitle(savedData.goalTitle);
      setGoalTime(savedData.goalTime);
      setTimeSpent(savedData.timeSpent);
      setIsRunning(false); // Set isRunning to false on page refresh
      setTaskCompleted(savedData.taskCompleted);
    }
  }, []);

  // Save data to localStorage whenever relevant state changes
  useEffect(() => {
    localStorage.setItem(
      "timeTrackerData",
      JSON.stringify({
        userEmail,
        goalTitle,
        goalTime,
        timeSpent,
        isRunning,
        taskCompleted,
      })
    );
  }, [userEmail, goalTitle, goalTime, timeSpent, isRunning, taskCompleted]);

  const handleStartStop = () => {
    if (
      !userEmail ||
      !goalTitle ||
      (goalTime.hours <= 0 && goalTime.minutes <= 0)
    ) {
      setError("Please fill out all fields with valid information.");
      return;
    }
    setError("");

    if (isRunning) {
      clearInterval(intervalId);
      setIsRunning(false);
    } else {
      setTaskCompleted(false);
      const id = setInterval(() => {
        setTimeSpent((prev) => prev + 1);
      }, 1000);
      setIntervalId(id);
      setIsRunning(true);
    }
  };

  const handleNewTask = () => {
    if (isRunning) {
      clearInterval(intervalId);
    }
    setUserEmail("");
    setGoalTitle("");
    setGoalTime({ hours: 0, minutes: 0 });
    setTimeSpent(0);
    setIsRunning(false);
    setTaskCompleted(false);
    setError("");
    localStorage.removeItem("timeTrackerData");
  };

  const totalGoalTime = goalTime.hours * 3600 + goalTime.minutes * 60;
  const percentageCompleted = Math.min(
    100,
    Math.floor((timeSpent / totalGoalTime) * 100)
  );

  const getRandomQuoteAndImage = () => {
    let randomQuoteIndex = Math.floor(Math.random() * quotes.length);
    let randomSubquoteIndex = Math.floor(Math.random() * subquotes.length);

    // Ensure that quote and subquote are not the same
    while (randomSubquoteIndex === randomQuoteIndex) {
      randomSubquoteIndex = Math.floor(Math.random() * subquotes.length);
    }

    return {
      quote: quotes[randomQuoteIndex],
      subquote: subquotes[randomSubquoteIndex],
    };
  };

  const sendEmail = (percentage) => {
    emailjs.init("2N30-VaLt0BMq3UNc"); // Replace with your EmailJS public key

    const { quote, subquote } = getRandomQuoteAndImage();
    const subject =
      percentage === 100
        ? `Congratulations on completing your goal: ${goalTitle}!`
        : percentage >= 80
        ? `Just there! Keep going strong! You're ${percentage}% done with your goal: ${goalTitle}.`
        : percentage >= 50
        ? `You are in Middle Earth! Keep pushing forward! You're halfway there with ${percentage}% completed for your goal: ${goalTitle}.`
        : `You're making progress! ${percentage}% of your goal: ${goalTitle} is complete. Keep up the great work!`;

    const message = `
      Goal: ${goalTitle}
      Progress: ${percentage}%
    `;

    const templateParams = {
      to_email: userEmail,
      subject,
      message,
      quote,
      subquote,
    };

    emailjs
      .send("service_coq471i", "template_oou16jo", templateParams)
      .then((response) => {
        console.log("Email sent successfully:", response);
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
      });
  };

  useEffect(() => {
    if (isRunning) {
      if ([20, 50, 80, 100].includes(percentageCompleted)) {
        sendEmail(percentageCompleted);
      }
      if (percentageCompleted >= 100) {
        clearInterval(intervalId);
        setIsRunning(false);
        setTaskCompleted(true);
        localStorage.removeItem("timeTrackerData");
      }
    }
  }, [percentageCompleted]);
  
  useEffect(() => {
    // On component unmount, do not pause unless explicitly paused
    return () => {
      if (!isRunning) {
        clearInterval(intervalId);
      }
    };
  }, [isRunning, intervalId]);
  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs}h ${mins}m ${secs}s`;
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 to-gray-800 text-white p-6 flex flex-col items-center justify-center">
      <h1 className="text-4xl font-bold text-purple-400">Commit Mail</h1>
      <p className="bg-gray-800 text-purple-400 text-sm sm:text-base md:text-lg lg:text-xl font-medium px-3 sm:px-4 md:px-6 py-2 sm:py-3 md:py-4 rounded-lg shadow-lg border border-purple-500 my-4 sm:my-6 leading-relaxed">
        "Inbox Invasion: Your Goals' Sneaky Sidekick! 📧💪"
      </p>

      {!taskCompleted && (
        <div className="bg-gray-800 border border-gray-700 text-white p-8 rounded-lg shadow-lg w-full max-w-md">
          {error && <p className="text-red-400 mb-4">{error}</p>}

          <div className="mb-4">
            <label className="block text-gray-300 mb-2">User Email:</label>
            <input
              type="email"
              className="w-full p-3 border rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              disabled={isRunning}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Goal Title:</label>
            <input
              type="text"
              className="w-full p-3 border rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={goalTitle}
              onChange={(e) => setGoalTitle(e.target.value)}
              disabled={isRunning}
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-300 mb-2">Goal Time:</label>
            <div className="flex space-x-4">
              <input
                type="number"
                className="w-1/2 p-3 border rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="Hours"
                value={goalTime.hours}
                onChange={(e) =>
                  setGoalTime({
                    ...goalTime,
                    hours: Math.max(0, parseInt(e.target.value, 10) || 0),
                  })
                }
                disabled={isRunning}
              />
              <input
                type="number"
                className="w-1/2 p-3 border rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="Minutes"
                value={goalTime.minutes}
                onChange={(e) =>
                  setGoalTime({
                    ...goalTime,
                    minutes: Math.max(0, parseInt(e.target.value, 10) || 0),
                  })
                }
                disabled={isRunning}
              />
            </div>
          </div>

          {totalGoalTime > 0 && (
            <>
              <div className="mb-4">
                <div className="h-4 w-full bg-gray-700 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-purple-500 rounded-full transition-all duration-300 ease-in-out"
                    style={{ width: `${percentageCompleted}%` }}
                  ></div>
                </div>
                <p className="text-sm text-gray-400 mt-2">
                  {percentageCompleted}% completed
                </p>
              </div>

              <div className="flex justify-between items-center mb-6">
                <p className="text-lg font-bold text-purple-400">
                  Time Left:{" "}
                  {formatTime(Math.max(0, totalGoalTime - timeSpent))}
                </p>
                <p className="text-lg font-bold text-gray-400">
                  Time Spent: {formatTime(timeSpent)}
                </p>
              </div>
            </>
          )}

          <div className="flex justify-center space-x-4">
            <button
              className={`flex items-center justify-center px-6 py-3 rounded-full text-lg font-semibold transition duration-300 ${
                isRunning
                  ? "bg-red-500 hover:bg-red-600"
                  : "bg-green-500 hover:bg-green-600"
              }`}
              onClick={handleStartStop}
            >
              {isRunning ? (
                <>
                  <StopCircle size={24} className="mr-2" /> Pause
                </>
              ) : (
                <>
                  <PlayCircle size={24} className="mr-2" /> Start
                </>
              )}
            </button>

            {!isRunning && timeSpent > 0 && (
              <button
                className="flex items-center justify-center px-6 py-3 rounded-full text-lg font-semibold bg-blue-500 hover:bg-blue-600 transition duration-300"
                onClick={handleNewTask}
              >
                <PlusCircle size={24} className="mr-2" /> New Task
              </button>
            )}
          </div>
        </div>
      )}

      {taskCompleted && (
        <div className="bg-gray-800 border border-gray-700 text-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
          <h2 className="text-3xl font-bold text-green-400 mb-4">
            🎉 Congratulations! 🎉
          </h2>
          <p className="text-xl mb-6">
            You have successfully completed your task:{" "}
            <span className="text-purple-400 font-semibold">{goalTitle}</span>
          </p>
          <button
            className="flex items-center justify-center mx-auto px-6 py-3 rounded-full text-lg font-semibold bg-blue-500 hover:bg-blue-600 transition duration-300"
            onClick={handleNewTask}
          >
            <PlusCircle size={24} className="mr-2" /> Start New Task
          </button>
        </div>
      )}

      <div>
        <p className="bg-gray-800 text-purple-400 text-base font-medium px-4 py-3 rounded-lg shadow-lg border border-purple-500 mt-4 sm:px-6 sm:py-4 sm:text-lg sm:mt-6">
          "Watch out! Your aspirations are plotting an email ambush. These
          clever little reminders will pop up when you least expect it, giving
          your productivity a playful nudge and your accountability a cheeky
          boost. It's like having a tiny, digital cheerleader hiding in your
          spam folder. Who knew your inbox could be such a motivational
          mastermind?"
        </p>
      </div>
    </div>
  );
}

export default App;
