import React, { useState, useEffect, useCallback } from 'react';
import { Chess } from 'chess.js';
import { Chessboard } from 'react-chessboard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import Confetti from 'react-confetti';
import toast, { Toaster } from 'react-hot-toast';

function ChessAI() {
  const [game, setGame] = useState(new Chess());
  const [fen, setFen] = useState(game.fen());
  const [playerTimer, setPlayerTimer] = useState(600); // 10 minutes in seconds
  const [aiTimer, setAiTimer] = useState(600);
  const [isPlayerTurn, setIsPlayerTurn] = useState(true);
  const [lastMove, setLastMove] = useState(null);
  const [showCelebration, setShowCelebration] = useState(false);
  const [showDanger, setShowDanger] = useState(false);
  const [highlightedSquares, setHighlightedSquares] = useState({});
  const [selectedPiece, setSelectedPiece] = useState(null);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const Backend = isMobile ? TouchBackend : HTML5Backend;

  // Load game state from local storage
  useEffect(() => {
    const savedGame = localStorage.getItem('chessAI-game');
    if (savedGame) {
      const parsedState = JSON.parse(savedGame);
      setGame(new Chess(parsedState.fen));
      setFen(parsedState.fen);
      setPlayerTimer(parsedState.playerTimer);
      setAiTimer(parsedState.aiTimer);
      setIsPlayerTurn(parsedState.isPlayerTurn);
      setLastMove(parsedState.lastMove);
    }
  }, []);

  // Save game state to local storage
  useEffect(() => {
    localStorage.setItem(
      'chessAI-game',
      JSON.stringify({
        fen,
        playerTimer,
        aiTimer,
        isPlayerTurn,
        lastMove,
      })
    );
  }, [fen, playerTimer, aiTimer, isPlayerTurn, lastMove]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (isPlayerTurn) {
        setPlayerTimer((prevTime) => Math.max(0, prevTime - 1));
      } else {
        setAiTimer((prevTime) => Math.max(0, prevTime - 1));
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [isPlayerTurn]);

  const onDrop = useCallback((sourceSquare, targetSquare) => {
    handleMove(sourceSquare, targetSquare);
  }, [game]);

  const handleMove = (sourceSquare, targetSquare) => {
    try {
      const move = game.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: 'q', // always promote to a queen for simplicity
      });

      if (move === null) {
        // Illegal move
        return false;
      }

      if (move.captured) {
        setShowCelebration(true);
        setTimeout(() => setShowCelebration(false), 2000);
      }

      setFen(game.fen());
      setLastMove({ from: sourceSquare, to: targetSquare });
      setHighlightedSquares({});
      setIsPlayerTurn(false);
      setSelectedPiece(null);

      // Check if the game is over
      if (game.isGameOver()) {
        endGame();
      } else {
        setTimeout(makeAIMove, 500); // Give the AI some time to "think"
      }
      return true;
    } catch (error) {
      console.error('Error during move:', error);
      return false;
    }
  };

  const makeAIMove = useCallback(() => {
    try {
      const moves = game.moves();
      if (moves.length > 0) {
        const move = moves[Math.floor(Math.random() * moves.length)];
        const aiMove = game.move(move);

        if (aiMove.captured) {
          setShowDanger(true);
          setTimeout(() => setShowDanger(false), 2000);
        }

        setFen(game.fen());
        setIsPlayerTurn(true);

        // Check if the game is over
        if (game.isGameOver()) {
          endGame();
        }
      }
    } catch (error) {
      console.error('Error during AI move:', error);
    }
  }, [game]);

  const endGame = () => {
    const winner = game.inCheckmate() ? (isPlayerTurn ? 'AI' : 'You') : 'Draw';
    toast.success(`Game Over! ${winner === 'Draw' ? "It's a draw!" : `${winner} won!`}`, {
      duration: 5000,
      position: 'top-center',
    });
    setTimeout(resetGame, 5000); // Reset the game after 5 seconds
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const resetGame = () => {
    const newGame = new Chess();
    setGame(newGame);
    setFen(newGame.fen());
    setPlayerTimer(600);
    setAiTimer(600);
    setIsPlayerTurn(true);
    setLastMove(null);
    setShowCelebration(false);
    setShowDanger(false);
    setHighlightedSquares({});
    setSelectedPiece(null);
    localStorage.removeItem('chessAI-game');
  };

  const onPieceClick = (square) => {
    const piece = game.get(square);
    if (selectedPiece === square) {
      setSelectedPiece(null);
      setHighlightedSquares({});
    } else if (piece && piece.color === 'w' && isPlayerTurn) {
      setSelectedPiece(square);
      const moves = game.moves({ square, verbose: true });
      const squaresToHighlight = moves.reduce((highlight, move) => {
        highlight[move.to] = { backgroundColor: 'rgba(0, 255, 0, 0.4)' };
        return highlight;
      }, {});
      setHighlightedSquares(squaresToHighlight);
    } else if (selectedPiece && highlightedSquares[square]) {
      handleMove(selectedPiece, square);
    }
  };

  return (
    <DndProvider backend={Backend}>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 p-4 text-white">
        <div className="flex items-center justify-between w-full max-w-md mb-4">
          <span className="text-2xl font-bold">AI VS You Chess</span>
          <button
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-200 ml-4"
            onClick={resetGame}
          >
            Reset
          </button>
        </div>
        <div className="mb-4 flex justify-between w-full max-w-md">
          <span>Player: {formatTime(playerTimer)}</span>
          <span>AI: {formatTime(aiTimer)}</span>
        </div>
        <div className="w-full max-w-md">
          <Chessboard 
            position={fen} 
            onPieceDrop={onDrop}
            onSquareClick={onPieceClick}
            customBoardStyle={{
              borderRadius: '8px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.8)',
            }}
            customSquareStyles={{
              ...(lastMove && {
                [lastMove.from]: { backgroundColor: 'rgba(255, 255, 0, 0.4)' },
                [lastMove.to]: { backgroundColor: 'rgba(255, 255, 0, 0.4)' },
              }),
              ...highlightedSquares,
            }}
          />
        </div>
        {showCelebration && <Confetti />}
        <Toaster />
      </div>
    </DndProvider>
  );
}

export default ChessAI;