import React, { useState } from "react";
import axios from "axios";

const PhoneSearch = () => {
  const [phone, setPhone] = useState("");
  const [result, setResult] = useState(null);

  const handleSearch = async () => {
    const fullPhone = `+91${phone}`;
    const url = `http://localhost:4000/api/phone/${fullPhone}`;

    try {
      const response = await axios.get(url);
      setResult(response.data);
    } catch (error) {
      setResult({ success: false, message: "Error occurred while searching." });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>True Caller Bot</h1>
      <h2 style={styles.subHeading}>
        Enter a number to find detailed information without leaking your entire contacts!
      </h2>
      <div style={styles.searchContainer}>
        <span style={styles.countryCode}>+91</span>
        <input
          type="text"
          placeholder="9876543210"
          style={styles.searchInput}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button style={styles.searchButton} onClick={handleSearch}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="30"
            height="30"
            viewBox="0 0 24 24"
          >
            <path d="M21.414,18.586c-0.287-0.287-1.942-1.942-2.801-2.801c-0.719,1.142-1.686,2.109-2.828,2.828	c0.859,0.859,2.514,2.514,2.801,2.801c0.781,0.781,2.047,0.781,2.828,0C22.195,20.633,22.195,19.367,21.414,18.586z"></path>
            <circle cx="11" cy="11" r="9" opacity=".35"></circle>
          </svg>
        </button>
      </div>
      {result && (
        <div style={styles.card}>
          {result.success ? (
            <>
              <p>Name: {result.name}</p>
              <p>Phone is valid: {result.valid ? "Yes" : "No"}</p>
              <p>Carrier: {result.carrier}</p>
              <p>Region: {result.region}</p>
              <p>Fraud Score: {result.fraud_score}</p>
              <p>Recent Abuse: {result.recent_abuse ? "Yes" : "No"}</p>
              <p>Spammer: {result.spammer ? "Yes" : "No"}</p>
              <p>Timezone: {result.timezone}</p>
            </>
          ) : (
            <p>{result.message}</p>
          )}
        </div>
      )}
      <div style={styles.footerContainer}>
        <p style={styles.footer}>As of now, we only provide Indian number information</p>
        <p style={styles.footer}>Info@mdzaid.us.kg</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "20px",
    minHeight: "100vh",
    padding: "30px",
    margin: 0,
    color: "#fff",
    background:
      "url(https://i.postimg.cc/MpXgTQ6x/3d-rendering-abstract-black-white-waves.jpg) center / cover no-repeat fixed",
  },
  heading: {
    fontSize: "2em",
    marginBottom: "10px",
    fontWeight: "bold",
    color: "#fff",
  },
  subHeading: {
    fontSize: "1em",
    marginBottom: "20px",
    color: "#ddd",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    border: "1px solid rgba(255, 255, 255, 0.35)",
    borderRadius: "20px",
    boxShadow: "0 0 10px 1px rgba(0, 0, 0, 0.25)",
    backdropFilter: "blur(4px)",
    padding: "10px",
    marginBottom: "20px",
  },
  countryCode: {
    fontWeight: "bold",
    marginRight: "10px",
    color: "#fff",
  },
  searchInput: {
    flexGrow: 1,
    border: "none",
    outline: "none",
    background: "transparent",
    color: "#fff",
    fontSize: "0.9em",
    padding: "5px",
  },
  searchButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
  },
  searchIcon: {
    width: "20px",
    height: "20px",
  },
  card: {
    maxWidth: "300px",
    minHeight: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "35px",
    border: "1px solid rgba(255, 255, 255, 0.35)",
    borderRadius: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    boxShadow: "0 0 10px 1px rgba(0, 0, 0, 0.25)",
    backdropFilter: "blur(5px)",
    color: "#fff",
  },
  footerContainer: {
    marginTop: "20px",
    textAlign: "center",
  },
  footer: {
    fontSize: "0.65em",
    color: "#bbb",
  },
};

export default PhoneSearch;
