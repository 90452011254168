import React, { useState, useEffect, useCallback } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { FaSearch, FaWater, FaWind } from "react-icons/fa";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    background-image: url('https://i.postimg.cc/MKMD4btC/abstract-wavy-background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const CompassContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const Compass = styled.div`
  position: relative;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
`;

const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 30px 20px 0 20px;
  border-color: red transparent transparent transparent;
  z-index: 1;
`;

const CompassCircle = styled.div`
  position: absolute;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.1s ease-out;
  background: url(https://purepng.com/public/uploads/large/purepng.com-compasscompassinstrumentnavigationcardinal-directionspointsdiagram-1701527842316onq7x.png)
    center no-repeat;
  background-size: contain;
`;

const MyPoint = styled.div`
  position: absolute;
  width: 20%;
  height: 20%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(8, 223, 69);
  border-radius: 50%;
  transition: opacity 0.5s ease-out;
`;


const FooterContent = styled.div`
  max-width: 72rem;
  margin: 0 auto;
  padding: 0 1rem;
  text-align: center;
`;

const FooterText = styled.p`
  font-size: 1.125rem;
`;

const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export default function CipherCompass() {
  const [pointDegree, setPointDegree] = useState(0);
  const [compassCircleTransformStyle, setCompassCircleTransform] = useState(
    "translate(-50%, -50%)"
  );
  const [myPointStyle, setMypointStyle] = useState(0);

  const calcDegreeToPoint = useCallback(() => {
    const point = {
      lat: 21.422487,
      lng: 39.826206,
    };
    return Math.round(Math.random() * 360); // Simulate random degree for demonstration
  }, []);

  const handler = useCallback((e) => {
    const compass = e.webkitCompassHeading || Math.abs(e.alpha - 360);
    const compassCircleTransform = `translate(-50%, -50%) rotate(${-compass}deg)`;
    setCompassCircleTransform(compassCircleTransform);

    if (
      (pointDegree < Math.abs(compass) &&
        pointDegree + 15 > Math.abs(compass)) ||
      pointDegree > Math.abs(compass + 15) ||
      pointDegree < Math.abs(compass)
    ) {
      setMypointStyle(0);
    } else if (pointDegree) {
      setMypointStyle(1);
    }
  }, [pointDegree]);

  const startCompass = useCallback(async () => {
    if (typeof DeviceOrientationEvent.requestPermission === 'function') {
      try {
        const response = await DeviceOrientationEvent.requestPermission();
        if (response === "granted") {
          window.addEventListener("deviceorientation", handler, true);
        } else {
          console.log("Permission to access device orientation was denied.");
        }
      } catch (error) {
        console.log("Device orientation permission request failed.");
      }
    } else {
      if ('ondeviceorientationabsolute' in window) {
        window.addEventListener("deviceorientationabsolute", handler, true);
      } else if ('ondeviceorientation' in window) {
        window.addEventListener("deviceorientation", handler, true);
      } else {
        console.log("Device orientation is not supported on this device.");
      }
    }
  }, [handler]);

  useEffect(() => {
    setPointDegree(calcDegreeToPoint());
    startCompass();

    return () => {
      window.removeEventListener("deviceorientation", handler, true);
      window.removeEventListener("deviceorientationabsolute", handler, true);
    };
  }, [calcDegreeToPoint, startCompass, handler]);

  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <CompassContainer>
          <Compass>
            <Arrow className="arrow" />
            <CompassCircle
              className="compass-circle"
              style={{ transform: compassCircleTransformStyle }}
            />
            <MyPoint className="my-point" style={{ opacity: myPointStyle }} />
          </Compass>
        </CompassContainer>
      </AppContainer>
    </>
  );
}
