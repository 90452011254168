import React, { useState, useEffect, useRef } from "react";

const Widget = () => {
  const [currentStation, setCurrentStation] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentStationIndex, setCurrentStationIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef(new Audio());
  const cardRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState("");

  const backgroundImages = [
    "https://i.postimg.cc/1z3SgjW2/abstract-dark-blurred-background-smooth-gradient-texture-color-shiny-bright-website-pattern-banner-h.jpg"
  ];
  const stations = [
    {
      name: "93.5 Red FM",
      url: "https://live.cmr24.net/CMR/Desi_Music-MQ/icecast.audio",
    },
    {
      name: "Fever 104 Fm",
      url: "https://nl4.mystreaming.net/uber/bollywoodlove/icecast.audio",
    },
    { name: "Bhojpuri Hitz", url: "https://t.ly/DS2By" },
    { name: "Driver & Barber Playlist", url: "https://t.ly/ECr9M" },
    { name: "Punjabi New Hits", url: "https://t.ly/l9XHs" },
    { name: "EDM DJ", url: "https://t.ly/cgVVy" },
    { name: "Radio Nasha", url: "https://rb.gy/cuyktu" },
    {
      name: "104.8 Ishq",
      url: "https://nl4.mystreaming.net/uber/lrbollywood/icecast.audio",
    },
    { name: "Punjabi Mix", url: "https://s2.radio.co/sbb640c97c/listen" },
    { name: "102.8 Radio City", url: "https://prclive1.listenon.in/Hindi" },
    { name: "Dance Wave", url: "https://dancewave.online/dance.mp3" },
    { name: "Smooth Jazz", url: "https://jking.cdnstream1.com/b22139_128mp3" },
    { name: "Kishore Kumar Hits", url: "https://rb.gy/cac586" },
    { name: "Namaste Bollywood", url: "https://t.ly/RgAza" },
    { name: "Mohammed Rafi Radio", url: "https://rb.gy/d9n6as" },
    { name: "Desi Punjab", url: "https://tinyurl.com/2wbk63dw" },
    { name: "Radio Lata Mangeshkar", url: "https://rb.gy/oph5pq" },
    { name: "Desi Bollywood", url: "https://www.desizoneradio.com/relay2" },
    {
      name: "24/7 English Comedy",
      url: "https://n2bb-e2.revma.ihrhls.com/zc4902?rj-ttl=5&rj-tok=AAABkP2UHFIAY3jvJI0CSPog0w",
    },
    { name: "Bollywood Gaane Purane", url: "https://rb.gy/10omw3" },
    { name: "Radio Bangla", url: "https://stream.radiotreetal.com/listen" },
    { name: "Goldy Evergreen", url: "https://rb.gy/cuyktu" },
  ];

  useEffect(() => {
    const audio = audioRef.current;
    const randomIndex = Math.floor(Math.random() * backgroundImages.length);
    setBackgroundImage(backgroundImages[randomIndex]);
    return () => {
      audio.pause();
    };
  }, []);

  const playStation = async (station, index) => {
    const audio = audioRef.current;

    if (currentStation === station.name && isPlaying) {
      audio.pause();
      setIsPlaying(false);
    } else {
      try {
        setIsLoading(true);
        if (audio.src !== station.url) {
          audio.src = station.url;
        }
        await audio.play();
        setCurrentStation(station.name);
        setIsPlaying(true);
        setCurrentStationIndex(index);
      } catch (error) {
        console.error("Error playing audio:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      playStation(stations[currentStationIndex], currentStationIndex);
    }
  };

  const playNextStation = () => {
    const nextIndex = (currentStationIndex + 1) % stations.length;
    playStation(stations[nextIndex], nextIndex);
  };

  const playPreviousStation = () => {
    const previousIndex =
      (currentStationIndex - 1 + stations.length) % stations.length;
    playStation(stations[previousIndex], previousIndex);
  };

  return (
    <section
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        maxWidth: "350px",
      }}
    >
      <div
        ref={cardRef}
        className="card"
        data-tilt
        data-tilt-glare
        data-tilt-max-glare="0.8"
        data-tilt-scale="1.1"
      >
        <center>
          <img
            src="https://cdn-icons-png.flaticon.com/512/3844/3844724.png"
            alt="Radio"
            style={{
              width: "100px",
              borderRadius: "50%",
              border: "2px solid #fff",
              marginBottom: "15px",
            }}
          />
          <h2
            style={{
              color: "#fff",
              fontWeight: "normal",
              fontSize: "20px",
              marginBottom: "10px",
            }}
          >
            Radio MDZ
          </h2>
          <p
            style={{
              color: "#fff",
              textAlign: "center",
              fontSize: "14px",
              marginBottom: "20px",
            }}
          >
            {currentStation
              ? `${currentStation}`
              : "Select a station to play"}
          </p>
          <div style={{ marginBottom: "20px" }}>
            <button
              onClick={playPreviousStation}
              style={{
                background: "#1DA1F2",
                width: "40px",
                height: "40px",
                fontSize: "18px",
                borderRadius: "50%",
                border: "none",
                marginRight: "10px",
                color: "#fff",
                transition: "0.4s all",
                cursor: "pointer",
              }}
            >
              <i className="fa fa-backward"></i>
            </button>
            <button
              onClick={togglePlayPause}
              style={{
                background: isPlaying ? "#e4405f" : "#4267B2",
                width: "50px",
                height: "50px",
                fontSize: "22px",
                borderRadius: "50%",
                border: "none",
                marginRight: "10px",
                color: "#fff",
                transition: "0.4s all",
                cursor: "pointer",
                position: "relative",
              }}
            >
              {isLoading ? (
                <div className="loader"></div>
              ) : (
                <i className={`fa ${isPlaying ? "fa-pause" : "fa-play"}`}></i>
              )}
            </button>
            <button
              onClick={playNextStation}
              style={{
                background: "#1DA1F2",
                width: "40px",
                height: "40px",
                fontSize: "18px",
                borderRadius: "50%",
                border: "none",
                marginRight: "0",
                color: "#fff",
                transition: "0.4s all",
                cursor: "pointer",
              }}
            >
              <i className="fa fa-forward"></i>
            </button>
          </div>
          <div
            style={{
              maxHeight: "150px",
              overflowY: "auto",
              marginBottom: "20px",
            }}
          >
            {stations.map((station, index) => (
              <div
                key={station.name}
                onClick={() => playStation(station, index)}
                style={{
                  color: currentStation === station.name ? "#10b981" : "#fff",
                  cursor: "pointer",
                  padding: "5px 0",
                  fontSize: "14px",
                }}
              >
                {station.name}
              </div>
            ))}
          </div>
        </center>
      </div>
      <style jsx global>{`
        @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
        @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

        * {
          font-family: poppins;
          box-sizing: border-box;
        }

        body {
          margin: 0;
          padding: 0;
          background-image: url("${backgroundImage}");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-attachment: fixed;
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .card {
          width: 100%;
          height: auto;
          padding: 20px;
          background: rgba(0, 0, 0, 0.7);
          backdrop-filter: blur(10px);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
          border-top: 2px solid rgba(255, 255, 255, 0.1);
          border-left: 2px solid rgba(255, 255, 255, 0.1);
          border-radius: 15px;
        }

        /* Custom scrollbar styles */
        ::-webkit-scrollbar {
          width: 5px;
        }

        ::-webkit-scrollbar-track {
          background: rgba(255, 255, 255, 0.1);
        }

        ::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.3);
          border-radius: 5px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: rgba(255, 255, 255, 0.5);
        }

        .loader {
          border: 3px solid #f3f3f3;
          border-top: 3px solid #3498db;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          animation: spin 1s linear infinite;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        @keyframes spin {
          0% {
            transform: translate(-50%, -50%) rotate(0deg);
          }
          100% {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }
        @media (max-width: 480px) {
          body {
            background-attachment: scroll;
          }

          .card {
            padding: 15px;
          }

          h2 {
            font-size: 18px !important;
          }

          p {
            font-size: 12px !important;
          }

          button {
            width: 35px !important;
            height: 35px !important;
            font-size: 16px !important;
          }

          button:nth-child(2) {
            width: 45px !important;
            height: 45px !important;
            font-size: 20px !important;
          }
        }

        @media (min-width: 481px) and (max-width: 1024px) {
          .card {
            max-width: 400px;
          }
        }

        @media (min-width: 1025px) {
          .card {
            max-width: 450px;
          }
        }
      `}</style>
    </section>
  );
};

export default Widget;
