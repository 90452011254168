import React, { useState } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';

const EmailComposer = () => {
  const [to, setTo] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('Sending...');

    const templateParams = {
      to_email: to,
      subject: subject,
      message: body,
      from_name: 'Anonymous',
    };

    emailjs.init('2N30-VaLt0BMq3UNc'); // Replace with your EmailJS public key

    emailjs.send(
      'service_coq471i', // Replace with your EmailJS service ID
      'template_5srwn94', // Replace with your EmailJS template ID
      templateParams
    ).then((response) => {
      console.log('Email sent successfully:', response);
      setStatus('Email sent successfully!');
      setTo('');
      setSubject('');
      setBody('');
    }).catch((error) => {
      console.error('Failed to send email:', error);
      setStatus('Failed to send email. Please try again.');
    });
  };

  return (
    <BackgroundWrapper>
      <StyledForm onSubmit={handleSubmit}>
        <h2>Send Anonymous Email without any Mail</h2>
        <StyledInput
          type="email"
          placeholder="To"
          value={to}
          onChange={(e) => setTo(e.target.value)}
          required
        />
        <StyledInput
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />
        <StyledTextArea
          placeholder="Message"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          required
        />
        <ButtonWrapper>
          <StyledButton type="submit">
            <span className="shadow"></span>
            <span className="edge"></span>
            <span className="front text">Send</span>
          </StyledButton>
        </ButtonWrapper>
        {status && <StyledStatus>{status}</StyledStatus>}
        <StyledFooter>Your message will be sent anonymously & We guarantee 100% privacy. Your data will not be shared.</StyledFooter>
      </StyledForm>
    </BackgroundWrapper>
  );
};

const BackgroundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('https://i.postimg.cc/8cyMG90S/63151.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 1rem;
  box-sizing: border-box;
`;

const StyledForm = styled.form`
  max-width: 500px;
  width: 100%;
  padding: 1.8rem;
  background: linear-gradient(
    to bottom right,
    hsl(340deg 100% 32% / 0.8),
    hsl(340deg 100% 16% / 0.8)
  );
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);

  @media (max-width: 600px) {
    padding: 1.5rem;
    margin: 1rem;
  }

  h2 {
    text-align: center;
    color: #ffffff;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px hsl(345deg 100% 47%);
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  resize: vertical;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px hsl(345deg 100% 47%);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledButton = styled.button`
  position: relative;
  width: 60%;
  padding: 1rem;
  border: none;
  background: transparent;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  touch-action: manipulation;

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
  }

  .edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(
      to left,
      hsl(340deg 100% 16%) 0%,
      hsl(340deg 100% 32%) 8%,
      hsl(340deg 100% 32%) 92%,
      hsl(340deg 100% 16%) 100%
    );
  }

  .front {
    display: block;
    position: relative;
    padding: 12px 27px;
    border-radius: 12px;
    font-size: 1.1rem;
    color: white;
    background: hsl(345deg 100% 47%);
    will-change: transform;
    transform: translateY(-4px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
  }

  &:hover {
    filter: brightness(110%);
  }

  &:hover .front {
    transform: translateY(-6px);
    transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
  }

  &:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
  }

  &:hover .shadow {
    transform: translateY(4px);
    transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
  }

  &:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

const StyledFooter = styled.p`
  text-align: center;
  margin-top: 1rem;
  color: #ffffff;
  font-size: 0.9rem;
`;

const StyledStatus = styled.p`
  text-align: center;
  margin-top: 1rem;
  color: ${props => props.children.includes('successfully') ? '#00ff00' : '#ff0000'};
  font-weight: bold;
`;

export default EmailComposer;