import React, { useState, useEffect } from "react";

const SpeedTest = () => {
  const [currentScreen, setCurrentScreen] = useState("home");
  const [downloadSpeed, setDownloadSpeed] = useState(0);
  const [uploadSpeed, setUploadSpeed] = useState(0);
  const [testProgress, setTestProgress] = useState(0);
  const [testResults, setTestResults] = useState(null);
  const [error, setError] = useState(null);
  const [networkDetails, setNetworkDetails] = useState({});
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    const fetchNetworkDetails = async () => {
      try {
        const ipResponse = await fetch("https://api64.ipify.org?format=json");
        const ipData = await ipResponse.json();
        setIpAddress(ipData.ip);

        const ispResponse = await fetch(`https://ipinfo.io/${ipData.ip}/json?token=e4272d3aa2018c`);
        const ispData = await ispResponse.json();

        setNetworkDetails({
          city: ispData.city || "Unknown City",
          region: ispData.region || "Unknown Region",
          country: ispData.country || "Unknown Country",
          loc: ispData.loc || "Unknown Location",
          org: ispData.org || "Unknown Organization",
          postal: ispData.postal || "Unknown Postal",
        });
      } catch (error) {
        console.error("Error fetching network details:", error);
        setNetworkDetails({
          city: "Unknown",
          region: "Unknown",
          country: "Unknown",
          loc: "Unknown",
          org: "Unknown",
          postal: "Unknown",
        });
      }
    };

    fetchNetworkDetails();
  }, []);

  const startTest = () => {
    setError(null);
    setDownloadSpeed(0);
    setUploadSpeed(0);
    setTestProgress(0);
    setCurrentScreen("loading");
    setTimeout(() => setCurrentScreen("testing"), 2000);
  };

  const completeTest = async () => {
    try {
      // Perform download speed test
      const fileSize = 5 * 1024 * 1024; // 5MB file
      const startTime = new Date().getTime();
      const response = await fetch(
        `https://speed.cloudflare.com/__down?bytes=${fileSize}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      await response.arrayBuffer();
      const endTime = new Date().getTime();
      const durationInSeconds = (endTime - startTime) / 1000;
      const speedMbps = (
        (fileSize / durationInSeconds / (1024 * 1024)) *
        8
      ).toFixed(2);

      // Animate download speed
      for (let i = 0; i <= parseFloat(speedMbps); i++) {
        await new Promise((resolve) => setTimeout(resolve, 30));
        setDownloadSpeed(i);
        setTestProgress((i * 50) / parseFloat(speedMbps));
      }

      // Perform upload speed test
      const uploadStartTime = new Date().getTime();
      const uploadData = new ArrayBuffer(fileSize);
      await fetch("https://speed.cloudflare.com/__up", {
        method: "POST",
        body: uploadData,
      });
      const uploadEndTime = new Date().getTime();
      const uploadDurationInSeconds = (uploadEndTime - uploadStartTime) / 1000;
      const uploadSpeedMbps = (
        (fileSize / uploadDurationInSeconds / (1024 * 1024)) *
        8
      ).toFixed(2);

      // Animate upload speed
      for (let i = 0; i <= parseFloat(uploadSpeedMbps); i++) {
        await new Promise((resolve) => setTimeout(resolve, 30));
        setUploadSpeed(i);
        setTestProgress(50 + (i * 50) / parseFloat(uploadSpeedMbps));
      }

      setTestResults({
        download: speedMbps,
        upload: uploadSpeedMbps,
        ping: 74,
        jitter: 40,
        loss: 0,
      });
      setCurrentScreen("results");
    } catch (error) {
      setError(error.message);
      setCurrentScreen("error");
    }
  };

  useEffect(() => {
    if (currentScreen === "testing") {
      completeTest();
    }
  }, [currentScreen]);

  return (
    <div className="bg-gray-900 min-h-screen text-white pt-12">
      {currentScreen === "home" && (
        <div className="flex flex-col items-center justify-center h-screen">
          <h1 className="text-3xl font-bold mb-8">Internet Speed Test</h1>
          <button
            className="bg-blue-500 text-white rounded-full w-32 h-32 flex items-center justify-center text-2xl"
            onClick={startTest}
          >
            Start
          </button>
        </div>
      )}
      {currentScreen === "loading" && (
        <div className="flex items-center justify-center h-screen">
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            <p className="mt-4">Preparing speed test...</p>
          </div>
        </div>
      )}
      {currentScreen === "testing" && (
        <div className="flex flex-col items-center justify-center h-screen">
          <h2 className="text-2xl mb-4">
            Progress: {testProgress.toFixed(0)}%
          </h2>
          <div className="w-64 h-64 rounded-full border-4 border-blue-500 flex items-center justify-center mb-4 relative">
            <div
              className="absolute inset-0 rounded-full border-4 border-green-500"
              style={{
                clipPath: `polygon(50% 50%, -50% -50%, ${testProgress}% -50%)`,
                transform: "rotate(-90deg)",
              }}
            ></div>
            <div className="flex flex-col items-center justify-center z-10">
              <span className="text-4xl font-bold">
                {testProgress < 50
                  ? downloadSpeed.toFixed(1)
                  : uploadSpeed.toFixed(1)}{" "}
                Mbps
              </span>
              <span className="text-xl">
                {testProgress < 50 ? "Download" : "Upload"}
              </span>
            </div>
          </div>
        </div>
      )}
      {currentScreen === "results" && testResults && (
        <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg max-w-sm mx-auto">
          <h2 className="text-xl font-bold mb-4">Results</h2>
          <div className="flex justify-between mb-2">
            <div>
              <span className="text-gray-400">Download</span>
              <h3 className="text-2xl font-semibold">
                {testResults.download} Mbps
              </h3>
            </div>
            <div>
              <span className="text-gray-400">Upload</span>
              <h3 className="text-2xl font-semibold">
                {testResults.upload} Mbps
              </h3>
            </div>
          </div>
          <div className="flex justify-between mb-4">
            <div>
              <span className="text-gray-400">Ping</span>
              <h3 className="text-lg">{testResults.ping} ms</h3>
            </div>
            <div>
              <span className="text-gray-400">Jitter</span>
              <h3 className="text-lg">{testResults.jitter} ms</h3>
            </div>
            <div>
              <span className="text-gray-400">Loss</span>
              <h3 className="text-lg">{testResults.loss} %</h3>
            </div>
          </div>
          <div className="border-t border-gray-700 my-4"></div>
          <button
            className="bg-blue-500 text-white py-2 rounded-lg w-full mb-4"
            onClick={startTest}
          >
            <span className="flex items-center justify-center">
              <span className="animate-spin mr-2">🧭</span>
              TEST AGAIN
            </span>
          </button>
          <div className="border-t border-gray-700 my-4"></div>
          <div>
            <h4 className="font-semibold">Network details</h4>
            <p>IP Address: <span className="text-gray-400">{ipAddress}</span></p>
            <p>City: <span className="text-gray-400">{networkDetails.city}, {networkDetails.region}, {networkDetails.country}</span></p>
            <p>Server: <span className="text-gray-400">{networkDetails.org}</span></p>
            <p>Server Location: <span className="text-gray-400">{networkDetails.loc}</span></p>
            <p>Postal Code: <span className="text-gray-400">{networkDetails.postal}</span></p>
          </div>
        </div>
      )}
      {currentScreen === "error" && (
        <div className="flex flex-col items-center justify-center h-screen">
          <h2 className="text-2xl mb-4">Error</h2>
          <p className="text-red-500">{error}</p>
          <button
            className="bg-blue-500 text-white py-2 rounded-lg w-full max-w-xs mt-4"
            onClick={startTest}
          >
            Try Again
          </button>
        </div>
      )}
    </div>
  );
};

export default SpeedTest;
