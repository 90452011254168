import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaBroadcastTower,
  FaCloudSun,
  FaCompass,
  FaNewspaper,
  FaDelicious,
  FaFilm,
  FaQrcode,
  FaEraser,
  FaIntercom,
  FaCar,
  FaMailBulk,
  FaChess,
  FaRobot,
  FaTelegramPlane,
  FaFileDownload,
  FaImage,
} from "react-icons/fa";

const cardClasses =
  "p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 text-center cursor-pointer";

const Home = () => {
  const navigate = useNavigate();

  const bgImage = "https://i.postimg.cc/25qHKF3y/OANWZD0.jpg";

  const handleCardClick = (url) => {
    if (url.startsWith("http")) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  useEffect(() => {
    const visited = localStorage.getItem("hasVisited");

    if (!visited) {
      localStorage.setItem("hasVisited", "true");
      let currentCount = parseInt(localStorage.getItem("visitCount") || "0");
      currentCount += 1;
      localStorage.setItem("visitCount", currentCount.toString());
    }
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-cover bg-center" style={{ backgroundImage: `url(${bgImage})` }}>
      <main className="flex-grow max-w-6xl mx-auto px-4 py-8">
        <h2 className="text-4xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-indigo-600">
          All your trusty tools in one place
        </h2>
        <p className="text-xl text-dark mb-8">
          Simple, efficient, yet powerful!
        </p>

        <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-4">
          {apps.map((app, index) => (
            <Card
              key={index}
              icon={app.icon}
              text={app.name}
              onClick={() => handleCardClick(app.url)}
            />
          ))}
        </div>
      </main>

      <footer className="bg-gray-900 text-gray-300 py-3">
  <div className="max-w-6xl mx-auto px-4">
    <div className="flex flex-wrap justify-center items-center text-xs space-x-2">
      <div>
        © {new Date().getFullYear()} Trusty Tools
      </div>
      <div>•</div>
      
      <a
        href="https://mdzaid.us.kg"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-yellow-500 transition-colors duration-300"
      >
        mdzaid.us.kg
      </a>
      <div>•</div>
      
      <a
        href="mailto:info@mdzaid.us.kg"
        className="hover:text-yellow-500 transition-colors duration-300"
      >
        Info@mdzaid.us.kg
      </a>   
    </div>
  </div>
</footer>

    </div>
  );
};

const Card = ({ icon: Icon, text, onClick }) => {
  return (
    <div className={cardClasses} onClick={onClick}>
      <Icon className="text-3xl mb-2 text-indigo-600 mx-auto" />
      <p className="font-medium text-gray-800 text-sm">{text}</p>
    </div>
  );
};

const apps = [
  { icon: FaBroadcastTower, name: "Radio", url: "/radio" },
  { icon: FaCloudSun, name: "Weather", url: "/weather" },
  { icon: FaCompass, name: "Compass", url: "/compass" },
  { icon: FaNewspaper, name: "News", url: "/news" },
  { icon: FaDelicious, name: "Cartoon", url: "/cartoon" },
  { icon: FaFilm, name: "Movies & TV", url: "https://mztv.us.kg/" },
  { icon: FaQrcode, name: "Link to QR", url: "/qrcode" },
  { icon: FaCar, name: "Vehicle RC", url: "/vehicle" },
  { icon: FaImage, name: "Think Pot", url: "/thinkpot" },
  { icon: FaMailBulk, name: "Send Email", url: "/email" },
  { icon: FaIntercom, name: "Speed test", url: "/speed" },
  { icon: FaEraser, name: "BG Remover", url: "/bgremover" },
  { icon: FaChess, name: "AI Chess", url: "/chess" },
  { icon: FaRobot, name: "Commit Mail", url: "/commitmail" },
  { icon: FaTelegramPlane, name: "Temp-Mail", url: "/tempmail" },
  { icon: FaFileDownload, name: "File Share", url: "/fileshare" },
];

export default Home;
