import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import WeatherAPI from "../AppList/WeatherAPI";
import Compass from "../AppList/Compass";
import Home from "./Home";
import SpeedTest from "../AppList/SpeedTest";
import NewsApi from "../AppList/NewsApi";
import Tempmail from "../AppList/Tempmail";
import RadioLive from "../AppList/RadioLive";
import BGremover from "../AppList/BGremover";
import Cartoon from "../AppList/Cartoon";
import QrcodeGenerator from "../AppList/QrcodeGenerator";
import PhoneTrue from "../AppList/PhoneTrue";
import VehicleRCCheck from "../AppList/VehicleRC";
import Email from "../AppList/Email";
import ChessAI from "../AppList/ChessAI";
import Commitedmail from "../AppList/Commitedmail";
import Fileshare from "../AppList/Fileshare";
import ThinkPot from "../AppList/ThinkPot";
function Router() {
  const routes = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/weather",
      element: <WeatherAPI />,
    },
    {
      path: "/compass",
      element: <Compass />,
    },
    {
      path: "/speedtest",
      element: <SpeedTest />,
    },
    {
      path: "/news",
      element: <NewsApi />,
    },
    {
      path: "/qrcode",
      element: <QrcodeGenerator />,
    },
    {
      path: "/tempmail",
      element: <Tempmail />,
    },
    {
      path: "/radio",
      element: <RadioLive />,
    },
    {
      path: "/cartoon",
      element: <Cartoon />,
    },
    {
      path: "/bgremover",
      element: <BGremover />,
    },
    {
      path: "/truecall",
      element: <PhoneTrue />,
    },
    {
      path: "/speed",
      element: <SpeedTest />,
    },
    {
      path: "/thinkpot",
      element: <ThinkPot />,
    },
    {
      path: "/vehicle",
      element: <VehicleRCCheck />,
    },
    {
      path: "/email",
      element: <Email />,
    },
    {
      path: "/chess",
      element: <ChessAI />,
    },
    {
      path: "/commitmail",
      element: <Commitedmail />,
    },
    {
      path: "/fileshare",
      element: <Fileshare />,
    },
  ]);
  return (
    <div>
      {/* <Header/> */}
      <RouterProvider router={routes} />
      {/* <Footer/> */}
    </div>
  );
}

export default Router;
