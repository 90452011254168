import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import emailjs from "@emailjs/browser";
import axios from "axios";
import { Mail } from 'lucide-react';
import { uploadBytesResumable } from "firebase/storage";

// Initialize Firebase (replace with your own config)
const firebaseConfig = {
  apiKey: "AIzaSyA1U3geaIkiGNs6kzPf4dZDxSQQ5Bz0sqI",
  authDomain: "fileshare-d767c.firebaseapp.com",
  projectId: "fileshare-d767c",
  storageBucket: "fileshare-d767c.appspot.com",
  messagingSenderId: "642046775863",
  appId: "1:642046775863:web:dc63029264cc94e5b9e5b2",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

emailjs.init("J7Z6ggnyHUYOONE4I");

const FileSharing = () => {
  const [file, setFile] = useState(null);
  const [downloadURL, setDownloadURL] = useState("");
  const [shortURL, setShortURL] = useState("");
  const [email, setEmail] = useState("");
  const [senderName, setSenderName] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      handleUpload(e.target.files[0]);
    }
  };

  const generateShortURL = async (longUrl) => {
    try {
      const tinyResponse = await axios.get(
        `https://tinyurl.com/api-create.php?url=${encodeURIComponent(longUrl)}`
      );
      if (tinyResponse.data) {
        return tinyResponse.data;
      }
    } catch (tinyError) {
      console.error("Error with TinyURL:", tinyError);
      try {
        const isgdResponse = await axios.get(
          `https://is.gd/create.php?format=simple&url=${encodeURIComponent(
            longUrl
          )}`
        );
        if (isgdResponse.data) {
          return isgdResponse.data;
        }
      } catch (isgdError) {
        console.error("Error with is.gd:", isgdError);
      }
    }

    console.error("Failed to shorten URL with both services");
    return longUrl;
  };

  const handleUpload = async (fileToUpload) => {
    if (!fileToUpload) {
      setMessage("Please select a file first!");
      return;
    }
    setLoading(true);
    setProgress(0);
    setMessage("");
  
    try {
      const storageRef = ref(storage, "files/" + fileToUpload.name);
      const uploadTask = uploadBytesResumable(storageRef, fileToUpload);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.error("Error uploading file: ", error);
          setMessage("Error uploading file. Please try again.");
          setLoading(false);
        },
        async () => {
          const url = await getDownloadURL(storageRef);
          setDownloadURL(url);
          const shortUrl = await generateShortURL(url);
          setShortURL(shortUrl);
          setMessage("File uploaded successfully!");
          setLoading(false);
        }
      );
    } catch (error) {
      console.error("Error uploading file: ", error);
      setMessage("Error uploading file. Please try again.");
      setLoading(false);
    }
  };
  

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shortURL || downloadURL);
    setMessage("Link copied to clipboard!");
  };

  const handleSendEmail = async () => {
    if (!email || !downloadURL || !senderName) {
      setMessage(
        "Please enter an email, sender's name, and upload a file first!"
      );
      return;
    }
    setLoading(true);
    setMessage("");

    try {
      await emailjs.send("service_oqnz0bs", "template_tbh4564", {
        from_name: senderName,
        to_email: email,
        subject: `You have received some file from ${senderName}`,
        heading: `${senderName} have sent you something via MZ Tools Share with you, a secure file sending and receiving service.`,
        download_link: shortURL || downloadURL,
        file_name: file.name,
        file_size: `File size - ${(file.size / 1024).toFixed(2)} KB`,
      });
      setMessage("Email sent successfully!");
    } catch (error) {
      console.error("Error sending email: ", error);
      setMessage("Error sending email. Please try again.");
    }

    setLoading(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#8D985F] to-black py-6 flex flex-col justify-center sm:py-12">
      <h1 className="text-3xl font-bold mb-6 text-center text-white">File Sharing</h1>
      <div className="relative py-3 sm:max-w-lg sm:mx-auto w-full px-4 sm:px-0">
        <div className="relative px-4 py-8 bg-white/50 shadow-lg sm:rounded-3xl sm:p-10 backdrop-blur-md border border-[#8D985F]/25">
          <div className="max-w-md mx-auto">
            <label className="block mb-4 w-full text-sm text-black font-semibold">
              <span className="sr-only">Choose file</span>
              <input
                type="file"
                onChange={handleFileChange}
                className="block w-full text-sm text-black
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-white file:text-black
                  hover:file:bg-gray-100"
              />
            </label>

            {loading && (
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                <div 
                  className="bg-blue-600 h-2.5 rounded-full" 
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            )}

            {downloadURL && (
              <div className="mt-4 space-y-3">
                <div className="relative">
                  <input
                    type="text"
                    value={shortURL || downloadURL}
                    readOnly
                    className="w-full p-2 border rounded-md pr-10 bg-white/75"
                  />
                  <button
                    onClick={handleCopyLink}
                    className="absolute inset-y-0 right-0 flex items-center px-4 bg-green-600 text-white rounded-r-md hover:bg-green-700"
                  >
                    📋
                  </button>
                </div>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter recipient's email"
                  className="w-full p-2 border rounded-md bg-white/75"
                />
                <input
                  type="text"
                  value={senderName}
                  onChange={(e) => setSenderName(e.target.value)}
                  placeholder="Enter your name"
                  className="w-full p-2 border rounded-md bg-white/75"
                />
                <button
                  onClick={handleSendEmail}
                  disabled={loading || !downloadURL}
                  className="button w-full flex items-center justify-center gap-2 py-2 px-4 bg-[#424242] text-white rounded-md hover:bg-[#4d4d4d] transition-all duration-200 relative overflow-hidden"
                >
                  <Mail size={18} />
                  <span>Send Email</span>
                  <div className="arrow absolute right-4">›</div>
                </button>
              </div>
            )}

            {message && (
              <p className="mt-4 text-sm text-center text-black font-medium">{message}</p>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .button:hover .arrow {
          animation: slide-right 0.6s ease-out both;
        }
        @keyframes slide-right {
          0% {
            transform: translateX(-10px);
            opacity: 0;
          }
          100% {
            transform: translateX(0);
            opacity: 1;
          }
        }
        .button:active {
          transform: translate(1px, 1px);
        }
      `}</style>
    </div>
  );
};

export default FileSharing;