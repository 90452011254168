import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TempEmail = () => {
  const [email, setEmail] = useState(() => localStorage.getItem("tempEmail") || "");
  const [inbox, setInbox] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (!email) {
      generateEmail();
    } else if (!selectedEmail) {
      // Start the auto-refresh interval if no email is selected
      const newIntervalId = setInterval(refreshInbox, 5000);
      setIntervalId(newIntervalId);
    }

    return () => clearInterval(intervalId);
  }, [email, selectedEmail]);

  const generateEmail = async () => {
    try {
      const response = await axios.get(
        "https://www.1secmail.com/api/v1/?action=genRandomMailbox&count=1"
      );
      const newEmail = response.data[0];
      setEmail(newEmail);
      localStorage.setItem("tempEmail", newEmail);
      setInbox([]);
      setSelectedEmail(null);
    } catch (error) {
      console.error("Failed to generate email:", error);
    }
  };

  const refreshInbox = async () => {
    try {
      const [login, domain] = email.split("@");
      const response = await axios.get(
        `https://www.1secmail.com/api/v1/?action=getMessages&login=${login}&domain=${domain}`
      );
      setInbox(response.data);
    } catch (error) {
      console.error("Failed to refresh inbox:", error);
    }
  };

  const readEmail = async (id) => {
    try {
      clearInterval(intervalId); // Stop auto-refresh when reading an email
      const [login, domain] = email.split("@");
      const response = await axios.get(
        `https://www.1secmail.com/api/v1/?action=readMessage&login=${login}&domain=${domain}&id=${id}`
      );
      setSelectedEmail(response.data);
    } catch (error) {
      console.error("Failed to read email:", error);
    }
  };

  const downloadAttachment = (fileName) => {
    const [login, domain] = email.split("@");
    const url = `https://www.1secmail.com/api/v1/?action=download&login=${login}&domain=${domain}&id=${selectedEmail.id}&file=${fileName}`;
    window.open(url, "_blank");
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email);
    toast.success("Email copied to clipboard!");
  };

  const EmailTable = ({ emails, onEmailClick }) => {
    const tableClasses =
      "min-w-full bg-gray-900 rounded-lg shadow-md border border-gray-800 text-gray-300";
    const rowClasses =
      "border-b border-gray-700 hover:bg-gray-800 cursor-pointer transition-all duration-300";
    const cellClasses = "py-3 px-4 text-left";

    const TableRow = ({ sender, email, subject, onClick }) => (
      <tr className={rowClasses} onClick={onClick}>
        <td className={`${cellClasses} font-bold`}>
          {email}
          <div className="text-sm text-gray-500">{sender}</div>
          <div className="text-sm text-gray-300 mt-2">
            {subject.length > 50 ? `${subject.slice(0, 50)}...` : subject}
          </div>
        </td>
        <td className={`${cellClasses} text-right`}>
          <span className="text-blue-500 text-xl">&gt;</span>
        </td>
      </tr>
    );

    return (
      <table className={tableClasses}>
        <tbody>
          {emails.map((email) => (
            <TableRow
              key={email.id}
              sender={email.from.split("<")[0].trim()}
              email={email.from.split("<")[1]?.replace(">", "").trim() || ""}
              subject={email.subject}
              onClick={() => onEmailClick(email.id)}
            />
          ))}
        </tbody>
      </table>
    );
  };

  const Card = ({ email, onBack }) => {
    const cardClasses =
      "bg-gray-900 text-gray-300 p-4 rounded-lg shadow-md max-w-full overflow-hidden"; // Ensure max width for mobile
    const avatarClasses =
      "w-10 h-10 rounded-full bg-gray-800 text-gray-400 flex items-center justify-center text-lg font-bold";
    const titleClasses = "text-xl font-bold";
    const subtitleClasses = "text-gray-500";
    const contentClasses =
      "mt-4 text-gray-800 bg-white p-3 rounded-md overflow-auto max-h-96 text-sm break-words"; // Adjust content size and make background white

    return (
      <div className={cardClasses}>
        <button
          className="mb-4 text-sm font-bold text-blue-500 hover:text-blue-700"
          onClick={onBack}
        >
          &lt; Back to Inbox
        </button>
        <div className="flex items-center mb-4">
          <div className={avatarClasses}>{email.from.charAt(0)}</div>
          <div className="ml-4">
            <h2 className={titleClasses}>{email.from}</h2>
            <p className={subtitleClasses}>{email.date}</p>
          </div>
        </div>
        <div className="border-b border-gray-700 mb-4 pb-2">
          <p className={contentClasses}>{email.subject}</p>
        </div>
        <div className="mb-4 overflow-auto max-h-96">
          <div
            className={contentClasses}
            dangerouslySetInnerHTML={{
              __html: email.htmlBody || email.textBody,
            }}
          />
        </div>
        {email.attachments && email.attachments.length > 0 && (
          <div className="attachments mt-4">
            <h4 className="text-lg font-semibold mb-2">Attachments:</h4>
            <ul>
              {email.attachments.map((attachment) => (
                <li key={attachment.filename}>
                  <button
                    onClick={() => downloadAttachment(attachment.filename)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    {attachment.filename} ({(attachment.size / 1024).toFixed(2)} KB)
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white p-6">
      <ToastContainer position="top-center" autoClose={3000} />
      <h2 className="text-3xl font-extrabold mb-8 text-center">
        Temporary Email Service
      </h2>

      <div className="w-full max-w-lg p-6 bg-gray-800 text-gray-300 rounded-lg shadow-lg">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={email}
              readOnly
              className="flex-grow p-2 bg-gray-700 border border-gray-600 rounded-lg text-sm"
            />
            <button
              onClick={copyToClipboard}
              className="px-3 py-2 bg-gray-700 text-gray-400 hover:text-white rounded-lg"
            >
              Copy
            </button>
          </div>
          <div className="flex justify-between">
            <button
              onClick={generateEmail}
              className="px-3 py-2 bg-gray-700 text-gray-400 hover:text-white rounded-lg"
            >
              Change Email
            </button>
            <button
              onClick={refreshInbox}
              className="px-3 py-2 bg-gray-700 text-gray-400 hover:text-white rounded-lg"
            >
              Refresh Inbox
            </button>
          </div>
        </div>

        <h3 className="text-xl font-semibold mt-6 mb-4 text-center">Inbox</h3>

        {!selectedEmail ? (
          <EmailTable emails={inbox} onEmailClick={readEmail} />
        ) : (
          <Card
            email={selectedEmail}
            onBack={() => {
              setSelectedEmail(null);
              const newIntervalId = setInterval(refreshInbox, 5000); // Resume auto-refresh
              setIntervalId(newIntervalId);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TempEmail;
