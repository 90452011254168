import React, { useState, useEffect, useRef } from "react";
import QRCode from "qrcode.react";
import axios from "axios";

const QRCodeGenerator = () => {
  const [inputValue, setInputValue] = useState("");
  const [url, setUrl] = useState("");
  const [shortenedUrl, setShortenedUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [toasts, setToasts] = useState([]);
  const qrRef = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (toasts.length > 0) {
        setToasts((prevToasts) => prevToasts.slice(1));
      }
    }, 1000); // Reduced the duration for toasts display

    return () => clearTimeout(timer);
  }, [toasts]);

  const addToast = (type, content) => {
    setToasts((prevToasts) => {
      const newToast = { type, content };
      const toastExists = prevToasts.some(
        (toast) => toast.content === content && toast.type === type
      );
      return toastExists ? prevToasts : [newToast, ...prevToasts];
    });
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
    addToast("info", "Copied to clipboard!");
  };

  const handleGenerateQR = () => {
    try {
      new URL(inputValue);
      setUrl(inputValue);
      addToast("success", "QR Code generated successfully!");
    } catch (_) {
      addToast("error", "Please enter a valid URL for QR Code.");
    }
  };

  const handleShorten = async () => {
    if (!inputValue) {
      addToast("warning", "Please enter a URL to shorten.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://tinyurl.com/api-create.php?url=${encodeURIComponent(inputValue)}`
      );
      setShortenedUrl(response.data);
      addToast("success", "URL shortened successfully!");
    } catch (error) {
      console.error("Error shortening URL:", error);
      addToast("error", "Failed to shorten URL. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    const canvas = qrRef.current.querySelector("canvas");
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrcode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    addToast("info", "QR Code downloaded!");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-900 text-white">
      <div className="w-full max-w-md p-8 bg-gray-800 rounded-lg shadow-lg">
        <h1 className="mb-6 text-3xl font-bold text-center text-yellow-400">
          Link Shortener & QR Code Generator
        </h1>
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder="Enter URL"
          className="w-full p-3 mb-4 bg-gray-700 border-2 border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-yellow-400 text-white"
        />
        <div className="flex justify-between mb-4">
          <button
            onClick={handleGenerateQR}
            className="w-5/12 px-4 py-2 text-black transition bg-yellow-400 rounded hover:bg-yellow-500"
          >
            Generate QR Code
          </button>
          <button
            onClick={handleShorten}
            disabled={isLoading}
            className="w-5/12 px-4 py-2 text-black transition bg-yellow-400 rounded hover:bg-yellow-500 disabled:bg-gray-500"
          >
            {isLoading ? "Shortening..." : "Shorten URL"}
          </button>
        </div>
        {url && (
          <div className="mt-8 mb-4">
            <h2 className="mb-2 text-xl font-bold text-yellow-400">QR Code:</h2>
            <div className="flex justify-center bg-white p-4 rounded" ref={qrRef}>
              <QRCode value={url} />
            </div>
            <button
              onClick={handleDownload}
              className="mt-2 w-full px-4 py-2 text-black transition bg-yellow-400 rounded hover:bg-yellow-500"
            >
              Download QR Code
            </button>
          </div>
        )}
        {shortenedUrl && (
          <div className="mt-4">
            <h2 className="mb-2 text-xl font-bold text-yellow-400">Shortened URL:</h2>
            <a
              href={shortenedUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:underline"
            >
              {shortenedUrl}
            </a>
            <button
              onClick={() => handleCopy(shortenedUrl)}
              className="mt-2 w-full px-4 py-2 text-black transition bg-yellow-400 rounded hover:bg-yellow-500"
            >
              Copy Shortened URL
            </button>
          </div>
        )}
      </div>
      <footer className="mt-8 text-yellow-400">Info@mdzaid.us.kg</footer>
      <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50">
        {toasts.map((toast, index) => (
          <div
            key={index}
            className={`mb-2 p-1 rounded-lg shadow-lg text-white ${
              toast.type === "success"
                ? "bg-green-600"
                : toast.type === "error"
                ? "bg-red-600"
                : toast.type === "warning"
                ? "bg-yellow-600"
                : "bg-blue-600"
            }`}
          >
            {toast.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QRCodeGenerator;
