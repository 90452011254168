import React, { useState } from 'react';

const sharedClasses = {
  container: 'flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white p-4',
  textCenter: 'text-center',
  textHeader: 'text-4xl font-bold mb-2',
  textSubHeader: 'text-lg text-gray-500 mb-6',
  inputContainer: 'flex flex-col items-center mb-6',
  inputFile: 'mb-4 p-2 bg-gray-800 border border-gray-700 rounded w-full max-w-xs',
  button: 'px-4 py-2 rounded bg-red-600 hover:bg-red-700 text-white',
  errorText: 'text-red-500 mt-4',
  resultContainer: 'mt-6 text-center',
  resultImage: 'max-w-full max-h-screen rounded-lg',
};

const BackgroundRemover = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleRemoveBackground = async () => {
    if (!selectedImage) return;

    const formData = new FormData();
    formData.append('file', selectedImage);
    formData.append('providers', 'microsoft');

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNDZmZmJjMGItMDM1Yi00YjgxLTg3MGUtNjA4YTg1OGQzMmMxIiwidHlwZSI6ImFwaV90b2tlbiJ9.XUvPHBR7fg2SNW7ple_P-9o13nf8d5HMdRmB0U8vJwY`,
      },
      body: formData,
    };

    setLoading(true);
    setError(null);

    try {
      const response = await fetch('https://api.edenai.run/v2/image/background_removal', options);
      const json = await response.json();

      if (json.microsoft && json.microsoft.image_resource_url) {
        const imageUrl = json.microsoft.image_resource_url;
        setResultImage(imageUrl);
        // Automatically download the image
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'background_removed.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setError('Failed to get the result image.');
      }
    } catch (err) {
      setError('Error processing the image: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={sharedClasses.container}>
      <div className={sharedClasses.textCenter}>
        <h1 className={sharedClasses.textHeader}>Remove Image Background</h1>
        <p className={sharedClasses.textSubHeader}>Get a transparent background for any image.</p>
      </div>
      <div className={sharedClasses.inputContainer}>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          className={sharedClasses.inputFile}
        />
        <button
          onClick={handleRemoveBackground}
          className={sharedClasses.button}
          disabled={loading || !selectedImage}
        >
          {loading ? 'Processing...' : 'Remove Background'}
        </button>
      </div>
      {error && <div className={sharedClasses.errorText}>{error}</div>}
      {resultImage && (
        <div className={sharedClasses.resultContainer}>
          <h2 className={sharedClasses.textHeader}>Background Removed:</h2>
          <img src={resultImage} alt="Result" className={sharedClasses.resultImage} />
        </div>
      )}
    </div>
  );
};

export default BackgroundRemover;
